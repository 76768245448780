import React, { Fragment } from 'react';
import Image from 'next/image';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Title, Anchor } from '@sumup/circuit-ui';
import { Globe } from '@sumup/icons';
import { Theme } from '@sumup/design-tokens';

import img from '~/static/home-bg-image.png';
import Meta from '~/shared/components/Meta';
import Logo from '~/shared/components/icons/Logo';
import { AVAILABLE_WEBSITES_FOR_MAIN } from '~/shared/constants/locales';
import { StyledFlagIcon } from '~/shared/components/Footer/components/CountrySelect/CountrySelect';
import {
  SELECTED_LOCALE_COOKIE,
  SELECTED_LOCALE_COOKIE_MAX_AGE_DAYS,
} from '~/shared/constants/cookies';
import useCookies from '~/shared/hooks/use-cookies';
import SocialIcons from '~/shared/components/Footer/components/SocialIcons';
import { SOCIALS } from '~/shared/components/icons/SocialIcon/SocialIcon';
import { RequestType } from '~/shared/providers/RequestContext/RequestContext';

const Header = styled('div')(
  ({ theme }) => css`
    padding: ${theme.spacings.mega};
    margin-bottom: ${theme.spacings.giga};
  `,
);

const StyledLogo = styled(Logo)(
  ({ theme }) => css`
    width: 92px;

    ${theme.mq.mega} {
      width: 110px;
    }
  `,
);

const StyledImage = styled(Image)(
  ({ theme }) => css`
    width: auto;
    height: auto;
    width: 100%;
    max-width: 100%;
    border-radius: 24px;

    ${theme.mq.mega} {
      width: 100%;
    }
  `,
);

const Main = styled('main')(
  ({ theme }) => css`
    padding: 0 ${theme.spacings.mega};
    min-height: 659px;
  `,
);

const Footer = styled('footer')(
  ({ theme }) => css`
    margin: ${theme.spacings.mega};
    margin-bottom: 0;

    ul {
      display: flex;
      gap: ${theme.spacings.mega};
      list-style-type: none;
    }
  `,
);

const FooterContainer = styled('div')(
  ({ theme }) => css`
    border-top: ${theme.borderWidth.kilo} solid var(--cui-border-subtle);
    padding: ${theme.spacings.mega} 0;
    display: flex;
    gap: ${theme.spacings.tera};
    align-items: center;
    justify-content: space-between;
    max-width: 1440px;
    margin: 0 auto;

    a {
      margin: 0;
      margin-right: ${theme.spacings.kilo};
    }

    a:last-child {
      margin-right: 0;
    }

    ${theme.mq.mega} {
      justify-content: flex-start;
      padding: ${theme.spacings.mega};

      a {
        margin-top: ${theme.spacings.kilo};
        margin-bottom: ${theme.spacings.kilo};
        margin-left: 0;
        margin-right: ${theme.spacings.giga};
      }
    }
  `,
);

const Container = styled('div')(
  () => css`
    max-width: 100%;
    margin: 0 auto;
    max-width: 1440px;
  `,
);

const StyledList = styled('ul')(
  ({ theme }) => css`
    list-style-type: none;
    height: 100%;
    column-count: auto;
    padding: ${theme.spacings.kilo};

    ${theme.mq.untilMega} {
      flex-wrap: nowrap;
      max-height: 100%;
      overflow-x: hidden;
    }

    ${theme.mq.mega} {
      column-count: 2;
    }

    ${theme.mq.tera} {
      column-count: 4;
    }

    img {
      position: inherit;
      top: 0;
      left: 0;
    }

    li {
      position: relative;
      padding: ${theme.spacings.kilo} ${theme.spacings.kilo};
      display: flex;
      gap: ${theme.spacings.kilo};
    }

    li > a {
      color: var(--cui-fg-normal);
      text-decoration: none;
    }

    li:hover,
    li:focus {
      outline: none;
    }

    li:focus:not(:focus-visible) {
      box-shadow: none;
    }
  `,
);

const Grid = styled('div')(
  ({ theme }) => css`
    display: grid;
    grid-template-areas:
      'title'
      'list'
      'image';
    gap: ${theme.spacings.giga};
    grid-auto-rows: auto;

    ${theme.mq.mega} {
      grid-template-columns: minmax(min-content, 515px) auto;
      grid-template-rows: auto 1fr;
      grid-template-areas:
        'title list'
        'image list';
      max-height: 60vh;
      row-gap: 0;
    }
  `,
);

const TitleContainer = styled('div')(
  ({ theme }) => css`
    grid-area: title;
    background: var(--cui-bg-strong);
    border-radius: 24px;
    padding: ${theme.spacings.peta} ${theme.spacings.giga};
    color: var(--cui-fg-on-strong);
    margin-bottom: ${theme.spacings.byte};
  `,
);

const ImageContainer = styled('div')(
  () => css`
    grid-area: image;
  `,
);

const ListContainer = styled('div')(
  () => css`
    grid-area: list;
  `,
);

const ListWrapper = styled('div')(
  ({ theme }) => css`
    height: 493px;
    overflow-y: scroll;

    ${theme.mq.tera} {
      height: 600px;
      overflow-y: hidden;
    }
  `,
);

const Label = styled('span')(
  ({ theme }) => css`
    font-style: italic;
    font-size: 2rem;

    strong {
      font-weight: ${theme.fontWeight.bold};
      font-style: normal;
    }

    ${theme.mq.mega} {
      font-size: 2.62rem;
      line-height: ${theme.typography.title.four.lineHeight};
    }
  `,
);

const globeIconStyles = ({ ...theme }: Theme) => css`
  display: block;
  position: absolute;
  top: ${theme.spacings.kilo};
  left: ${theme.spacings.kilo};
  width: 24px;
  height: 24px !important;
  pointer-events: none;
  z-index: 40;
`;

export const StyledGlobeIcon = styled(Globe)(globeIconStyles);

type WebsiteType = {
  label: string;
  countryCode: string;
  locale: string;
  href?: string;
};

type HomeProps = {
  request?: RequestType;
  websites: WebsiteType[];
  site: {
    meta: {
      facebook: string;
      instagram: string;
      twitter: string;
      linkedin: string;
      youtube: string;
    };
  };
};

const HomePage = ({ websites, request }: HomeProps) => {
  const [, setCookie] = useCookies();
  const handleGoClick = (website: WebsiteType) => () => {
    if (!website) {
      return;
    }

    const targetLocale = website.locale.toLowerCase();
    setCookie(SELECTED_LOCALE_COOKIE, targetLocale, {
      expires: SELECTED_LOCALE_COOKIE_MAX_AGE_DAYS,
    });
    const finalUrl = `${website.href}${window.location.search}`;
    window.location.assign(finalUrl);
  };

  const websitesWithHref = websites.map((websiteItem) => {
    const { origin } = request;
    const baseUrl = origin;

    const url = new URL(`${websiteItem.locale.toLowerCase()}/`, baseUrl);
    /* eslint-disable no-param-reassign */
    websiteItem.href = url.href;
    return websiteItem;
  });

  return (
    <Fragment>
      <Meta
        request={request}
        isCountrySelectionPage
        websitesWithHref={websitesWithHref}
      />
      <div>
        <Header>
          <Container>
            <StyledLogo />
          </Container>
        </Header>
        <Main>
          <Container data-selector="country_list@main">
            <Grid>
              <TitleContainer>
                <Title as="h1" size="four" style={{ color: 'white' }}>
                  Explore our business solutions
                </Title>
              </TitleContainer>

              <ImageContainer>
                <StyledImage src={img} alt="SumUp Merchant" />
              </ImageContainer>

              <ListContainer>
                <Label>
                  <strong>Choose</strong> your country
                </Label>
                <ListWrapper>
                  <StyledList>
                    {websitesWithHref.map((websiteItem) => (
                      <li key={websiteItem.label}>
                        {!websiteItem.countryCode ? (
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-expect-error
                          <StyledGlobeIcon />
                        ) : (
                          <StyledFlagIcon
                            countryCode={websiteItem.countryCode.toLowerCase()}
                            key={websiteItem.countryCode}
                          />
                        )}
                        <Anchor
                          href={websiteItem.href}
                          onClick={handleGoClick(websiteItem)}
                          data-selector="country_list_item"
                        >
                          {websiteItem.label}
                        </Anchor>
                      </li>
                    ))}
                  </StyledList>
                </ListWrapper>
              </ListContainer>
            </Grid>
          </Container>
        </Main>
        <Footer data-selector="section@footer">
          <FooterContainer>
            <StyledLogo />
            <div>
              <SocialIcons withLabel />
            </div>
          </FooterContainer>
        </Footer>
      </div>
    </Fragment>
  );
};

HomePage.getInitialProps = async (): Promise<HomeProps> => ({
  websites: AVAILABLE_WEBSITES_FOR_MAIN,
  site: {
    meta: {
      [SOCIALS.FACEBOOK]: 'SumUp',
      [SOCIALS.INSTAGRAM]: 'sumup',
      [SOCIALS.TWITTER]: 'sumup',
      [SOCIALS.LINKEDIN]: 'sumup',
      [SOCIALS.YOUTUBE]: 'SumUpGlobal',
    },
  },
});

export default HomePage;
